import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoginComponent } from 'src/app/authentiction/login/login.component';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  modalRef?: BsModalRef;
  userDetails: any;
  address: any;
  search: any = '';
  private subject: Subject<string> = new Subject();
  clearSearch: boolean;
  selectedKey: boolean = false;
  searchSuggestionList: any = []
  cartCount: any;
  currentUrl: any;
  user: any;
  currentLang: any;
  lat: number;
  lng: number;
  pickup = { lat: 0, lng: 0, address: '' };
  geoCoder: any;

  zoom: number = 12;
  location:FormGroup
  data: any;
  constructor(private modalService: BsModalService,private router: Router,
    private activateRoute: ActivatedRoute,private common: CommonService,private fb: FormBuilder) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            this.data= JSON.parse(localStorage.getItem('akunamatata_web_user'))
         }
        })

      this.getCurrentLocation();
      this.checkCurrentLocation(this);
      this.common.getProfile().subscribe(res => {
        this.data = res;
      })
     }

  ngOnInit(): void {
    // this.currentUrl = this.router.url;
    // this.subject.pipe(
    //   debounceTime(500)
    // ).subscribe(searchTextValue => {
    //   if (searchTextValue.length >= 1) {
    //     this.onSearchSuggestion(searchTextValue);
    //   } else if (searchTextValue.length < 1) {
    //     this.searchSuggestionList = [];
    //   }
    // });
    // this.common.getCart().subscribe(res => {
    //   this.cartCount = res;
    // });

    this.location = this.fb.group({
      address: [""],
      latitude: [""],
      longitude: [""],

    });
    this.common.getProfile().subscribe(res => {
      this.userDetails = res;
    })

    this.common.getLocation().subscribe(res => {
      if (res) {
        this.address = res['address'];
      }
    })
    if (localStorage.getItem('akunamatata_web_address_location')) {
      let locateData = JSON.parse(localStorage.getItem('akunamatata_web_address_location'));
      this.address = locateData['address'];
    }
  }
  openModal() {
    this.modalRef = this.modalService.show(LoginComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  // onSearchSuggestion(search) {
  //   this.common.getLocation().subscribe(res => {
  //     if (res) {
  //       let data = {
  //         latitude: res['latitude'],
  //         longitude: res['longitude'],
  //         search: search
  //       }
  //       // this.common.searchProduct(data).subscribe(res => {
  //       //   if (res['success']) {
  //       //     this.searchSuggestionList = res['data'];
  //       //     if (this.searchSuggestionList && this.searchSuggestionList.length === 0) {
  //       //       // this.merchantList = [];
  //       //       this.selectedKey = false;
  //       //     }
  //       //   }
  //       // })
  //     }
  //   })
  // }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var data
          this.lat = position.coords.latitude;
          this.lat = position.coords.longitude;
          this.zoom = 15;
          // this.pickup = { lat: this.lat, lng: this.lat, address: '' };
          this.getAddressDrop(this.pickup, '');
          data['latitude'] = this.lat
          data['longitude'] = this.lng
          localStorage.setItem('akunamatata_web_address_location',JSON.stringify(data))
        },
        (err) => {
          console.log(err);
        }
      );
    }

  }


  logOut(){
    let webAddress;

    if (localStorage.getItem('akunamatata_web_address_location')) {
      webAddress = localStorage.getItem('akunamatata_web_address_location');
    }

    if (localStorage.getItem('akunamatata_web_user')) {
      localStorage.clear();
      this.common.setProfile();
      this.router.navigateByUrl("/pages/home");
    }
    if (webAddress) {
      localStorage.setItem('akunamatata_web_address_location', webAddress);
      this.common.setLocation();
    }
  }

  onKeyUpp(value) {

    if (value === "") {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.location.get('address').patchValue(null);

    }
  }
  pickupChange(value) {

    if (this.pickup.lat === 0 && this.pickup.lng === 0) {
      this.location.get('address').patchValue(null);

    } else if (this.address !== value) {
      this.location.get('address').patchValue(null);

    }
  }
  handlePickUpChnage(address) {

    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickup.lat = (address.geometry.location.lat())
      this.pickup.lng = (address.geometry.location.lng())
      this.address = (address.formatted_address);
      this.location.get('address').patchValue(this.address);
      this.location.patchValue({
        latitude:this.pickup.lat,
        longitude:this.pickup.lng
      })

    }
  }

  getAddressDrop(latLng, formatted_address) {
    if(latLng){
      this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
        if (status === 'OK' && results.length > 0) {
          var isAddSimilar = false;
          results.forEach(addresses => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              this.location.controls['address'].patchValue(addresses.formatted_address);

              return false;
            }
          });
          if (!isAddSimilar) {
            this.location.controls['address'].patchValue(results[0].formatted_address);

          }
        } else {
        }
      });

    }
  }

  checkCurrentLocation(self) {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          self.permissionStatus = permissionStatus.state;
          if (permissionStatus.state === 'granted') {
          }
          permissionStatus.addEventListener('change', function () {
            if (this.state === 'granted') {
              self.getCurrentLocation();
            } else if (this.state === 'denied' || this.state === 'prompt') {
              return true

            }
          });
        });
    }
  }

  onSearchSuggestion(search) {
    this.common.getLocation().subscribe(res => {
      if (res) {
        let data = {
          latitude: res['latitude'],
          longitude: res['longitude'],
          search: search
        }
        // this.common.searchProduct(data).subscribe(res => {
        //   if (res['success']) {
        //     this.searchSuggestionList = res['data'];
        //     if (this.searchSuggestionList && this.searchSuggestionList.length === 0) {
        //       // this.merchantList = [];
        //       this.selectedKey = false;
        //     }
        //   }
        // })
      }
    })
  }

}
