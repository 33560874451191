import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { EmailVerificationComponent } from '../email-verification/email-verification.component';
import { PhoneOtpComponent } from '../phone-otp/phone-otp.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  submitted: boolean;
  forgetForm: FormGroup;
  user: any;
  type: string;
  phoneCheck: boolean=true;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Kuwait];
  showEmail: boolean=false;
  showPhone: boolean=false;
  constructor(public modalRef: BsModalRef, private modalService: BsModalService,private router: Router,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService) { 

      if(localStorage.getItem('email')){
        this.showEmail=true
      }
      if(localStorage.getItem('phone')){
        this.showPhone=true
      }
    }

  ngOnInit(): void {

    this.forgetForm = this.fb.group({
      email: [""],
      phone: [""],
      countryCode:[""]
    });
  }
  public customPatterns = { '0': { pattern: new RegExp('\[a-zA-Z\]')} };
  openEmailVerification() {
    this.modalRef.hide();
    this.modalService.show(EmailVerificationComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openOtp() {
    this.modalRef.hide();
    this.modalService.show(PhoneOtpComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  onSubmit() {
    this.submitted = true;
      let data=this.forgetForm.value
        if(this.showEmail == true){
          if(this.forgetForm.value.email){
            this.forgetForm.get("email").setValidators([Validators.required])
              data['type'] = "FORGOT";
              // data['countryCode'] =this.forgetForm.value
              data['email']=this.forgetForm.value.email
              delete data['phone']
              delete data['countryCode']
               this.https.httpPost('forgotPassword', data).subscribe((res: any) => {
                if (res['status']==200) {
                  let data ={
                    user:{
                      email:this.forgetForm.value.email,
    
                    }
                  }
                  data['isForget']=true
                    localStorage.setItem('otp', JSON.stringify(data));
                    this.openOtp();
                } 
              })
          }else{
            this.common.presentsToast('error', 'top-end','Email is Requried');
          }
      }else if(this.showPhone == true)
      {
        if(this.forgetForm.value.phone){
          var codeLength = this.forgetForm.value.phone.dialCode.length;
          this.forgetForm.patchValue({
            phone:this.forgetForm.value.phone.e164Number.slice(codeLength),
            countryCode:this.forgetForm.value.phone.dialCode,
          })
          data['type'] = "FORGOT";
         data['phone']=this.forgetForm.value.phone
          data['countryCode']=this.forgetForm.value.countryCode
          delete data['email']
           this.https.httpPost('forgotPassword', data).subscribe((res: any) => {
            if (res['status']==200) {
              let data ={
                user:{
                  phone:this.forgetForm.value.phone.e164Number.slice(codeLength),
                  countryCode:this.forgetForm.value.phone.dialCode,
                }
                
              }
              data['isPhoneForget']=true
                localStorage.setItem('otp', JSON.stringify(data));
                this.openOtp();
            } 
          })

        }else{
          this.common.presentsToast('error', 'top-end','Phone Number is Required');
        }
      }
    }

    emailcheck(event) {
      var flag = 0;
      // this.loginFormControls.value.phone=this.loginFormControls.value.email
      this.forgetForm.patchValue({ phone: this.forgetForm.value.email })
      if (this.forgetForm.value.email) {
        for (var i = 0; i < this.forgetForm.value.email.length; i++) {
          //console.log(i);
          if (this.forgetForm.value.email[i].charCodeAt(0) >= 48 && this.forgetForm.value.email[i].charCodeAt(0) <= 57) {
            //console.log('number', this.user.email[i]);
          } else {
            //console.log('email', this.user.email[i]);
            flag++;
          }
        }
        if (flag > 0) {
          this.type = 'email';
          this.phoneCheck = false;
          setTimeout(() => {
            // return document.getElementById('email').focus();
          });
        } else {
          this.type = 'number';
          this.phoneCheck = true;
          setTimeout(() => {
            // this.myDiv.nativeElement.innerHTML.type="text";
            // return document.getElementById('my-input-id').focus();
          });
        }
      }
    }
}
