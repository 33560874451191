import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { apiList } from './services/http/api-list';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import { NumberModuleModule } from './number-module/number-module.module';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { DialogOverviewDialog } from './pages/manage-payment/manage-payment.component';
import { StripeModule } from 'stripe-angular';
import { JwtHelperService } from '@auth0/angular-jwt';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    ModalModule.forRoot(),
    NgOtpInputModule,
    NgxSliderModule,
    MatSelectModule,
    MatInputModule,
    HttpClientModule,
    AccordionModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CarouselModule.forRoot(),
    NgxUiLoaderModule.forRoot({"bgsColor": "red","fgsColor": "#f57f2c",}),
    NgxIntlTelInputModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyC45rSV_FW5IVVxiB0NpI1dLS7pwhnAwsY'}),
    GooglePlaceModule,
    SocialLoginModule,
    NumberModuleModule,
    MatPaginatorModule,
    MatRadioModule,
    FormsModule,
    MatDialogModule,
    StripeModule.forRoot("pk_test_sKxEYHmSp9moHAkY3y7pGKjR00nk8KsHlJ")

    // BrowserAnimationsModule,
    // BsDropdownModule.forRoot()
  ],
  providers:  [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    }, {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '78461968061-71ujbme53ho8e8v2k6r7bkc0qne7ljsv.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('890053528298956')
          }
        ]
      } as SocialAuthServiceConfig
    },
    apiList,
    BsModalRef,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
