export class apiList {
  login: string = '/user/login';
  logout: string = '/user/logout';
  signUp: string = '/user/signUp';
  verifyOtp: string = '/user/verifyOtp';
  profile: string = '/user/profile';
  profilePic: string = '/user/profilePic';
  forgotPassword: string = '/user/forgotPassword';
  socialLogin: string = '/user/socialLogin';
  changePassword: string = '/user/changePassword';
  resendOtp: string = '/user/resendOtp';
  resetPassword: string = '/user/resetPassword';
  homescreen: string = '/user/homescreen';
  address: string = '/user/address';
  products: string = '/user/products';
  wishlist: string = '/user/wishlist';
  category: string = '/user/category';
  subcategory: string = '/user/subcategory';
  cart: string = '/user/cart';
  card: string = '/user/card';
  seller: string = '/user/seller';
  contactSeller: string = '/user/contactSeller';
  cms: string = '/user/cms';
  coupons: string = '/user/coupons';
  couponCharge: string = '/user/couponCharge';
  removeCoupon: string = '/user/removeCoupon';
  addressCharge: string = '/user/addressCharge';
  checkout: string = '/user/checkout';
  allsubscription: string = '/user/allsubscription';
  buysubscription: string = '/user/buysubscription';
  order: string = '/user/order';
  orderdetails: string = '/user/orderdetails';
  mysubscription:string = '/user/mysubscription';
}
