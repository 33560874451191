import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { PhoneLoginComponent } from '../phone-login/phone-login.component';
import { PhoneOtpComponent } from '../phone-otp/phone-otp.component';
import { SetupProfileComponent } from '../setup-profile/setup-profile.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef

  submitted: boolean;
  loginForm: FormGroup;
  isLoggedIn: boolean;
  userDetails: any;
  user: any;
  remember: boolean = false;
  uniqueDeviceId: any;

  dashboardData = new BehaviorSubject<any>({});
  yourRedirectURIVar: any = 'https://dev.webdevelopmentsolution.net/akunamatata/web/pages/home';
  clientId: any = 'dev.webdevelopmentsolution';
  jwtHelper = new JwtHelperService();


  constructor(public modalRef: BsModalRef, private modalService: BsModalService, private router: Router,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService, private socialAuthService: SocialAuthService,
    ) { 
      if (sessionStorage.getItem("rememberLoginEmail")) {
        let data = JSON.parse(sessionStorage.getItem("rememberLoginEmail"));
        this.remember = true;
        this.loginForm = this.fb.group({
          email: [data.email, [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
          password: [data.password, [Validators.required]]
        });
      } else {
        this.loginForm = this.fb.group({
          email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
          password: ["", [Validators.required]]
        });
      }
    }

  ngOnInit(): void {
  }
  phoneModal() {
    this.modalRef.hide();
    this.modalService.show(PhoneLoginComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }
  forgetPasswordModal(){
    localStorage.clear();
    localStorage.setItem('email','d')
    this.modalRef.hide();
    this.modalService.show(ForgetPasswordComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }
  signUpModal(){
    this.modalRef.hide();
    this.modalService.show(SignupComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openPhoneOtp() {
    this.modalRef.hide();
    this.modalService.show(PhoneOtpComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  setupProfile(){
    this.modalRef.hide();
    this.modalService.show(SetupProfileComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      if (this.remember) {
        sessionStorage.setItem(
          "rememberLoginEmail",
          JSON.stringify(this.loginForm.value)
        );
      } else {
        sessionStorage.clear();
      }
      let data = this.loginForm.value;
      let response = {};
      data['deviceType'] = "WEB";
       this.https.httpPost('login', data).subscribe((res: any) => {
        this.user = res['data']['user'];
        if (res['status']==200) {
          if (this.user.isEmailVerified == true) {
            this.isLoggedIn = true;
              this.setLoginData(res);
          }
          else {
            res['data']['isLogin']= true;
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            if (localStorage.getItem('akunamatata_web_user')) {
              this.userDetails = JSON.parse(localStorage.getItem('akunamatata_web_user'));
              this.openPhoneOtp();

            }
          }
        } 
      })
    } else {
      this.loginForm.markAllAsTouched();
    }
  }


  setLoginData(response) {
    if (this.isLoggedIn) {
      localStorage.setItem('akunamatata_web_user', JSON.stringify(response['data']));
      localStorage.setItem('accessToken',response['data']['token']);
      this.common.setProfile();
      if (response['data']['user'] && response['data']['user']['isEmailVerified'] === false) {
        this.openPhoneOtp();
      } else if (response['data']['user'] && response['data']['user']['isProfileCompleted'] === false) {
        this.setupProfile();
      }else
      {
        this.common.setProfile();
        // this.modalRef.hide();
       this.modalRef.hide();
       this.router.navigateByUrl("/pages/home");
        this.common.presentsToast('success', 'top-end','User Login Successfully, Welcome to Akunamatata');
      }
    }
  }


  signInWithFB(): void {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "FACEBOOK",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Akunamatata");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();
          } else {
            this.common.presentsToast('error', 'top-end',res['message'])

          }
        })
      }
    })
  }


  signInWithGoogle() {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "GOOGLE",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Akunamatata");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();

            } else {
            this.common.presentsToast('error', 'top-end',res['message'])
          }
        })
      }
    });
  }

  openAppleAuthWindow() {
    window.open(
      'https://appleid.apple.com/auth/authorize?' +
        `client_id=${this.clientId}&` +
        `redirect_uri=${encodeURIComponent(this.yourRedirectURIVar)}&` +
        //`redirect_uri=${this.yourRedirectURIVar}&` +
        'response_type=code id_token&' +
        'state=state&' +
        'nonce=nonce&' +
        //'scope=name email&' +
        'response_mode=fragment',
      '_self'
    );
  }
}
