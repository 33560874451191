<div class="siderbar-element">
  <div class="siderbar-block">
    <div class="sidebar-head ml-2">
      <h3 class="ff-playfair font-24 font-medium">Filter & Sorting</h3>
      <h4 class="ff-sanspro font-22 font-bold">Category</h4>
    </div>
    <div class="sidebar-fillter">
      <div class="custom-input-group w-100 mx-2"><input type="text" placeholder="Search" class="custom-control pe-5"
          (keyup)="category()" [(ngModel)]="searchCategory"><i
          class="mdi mdi-magnify font-30 right text-default cusor-point"></i>
      </div>
      <ul class="fillter-list">
        <li class="fillter-listing py-2">
          <mat-radio-group aria-label="Select an option" class="d-flex flex-column" [(ngModel)]="categroy">
            <mat-radio-button *ngFor="let data of categoryData" name="data?._id"
              class="example-radio-button radio-class" (change)="onCategoryChange($event,data?._id,data?.subcategories)"
              [value]="data?._id">
              {{data?.name}}
            </mat-radio-button>
          </mat-radio-group>
        </li>
      </ul>
    </div>
    <div class="sidebar-head ml-2" *ngIf="subCategoryData">
      <h4 class="ff-sanspro font-22 font-bold">Sub Category</h4>
    </div>
    <div class="sidebar-fillter" *ngIf="subCategoryData">
      <div class="custom-input-group w-100 mx-2"><input type="text" placeholder="Search" class="custom-control pe-5"
          (keyup)="subCategory('')" [(ngModel)]="searchSubCategory"><i
          class="mdi mdi-magnify font-30 right text-default cusor-point"></i>
      </div>
      <ul class="fillter-list">
        <li class="fillter-listing" *ngFor="let data of subCategoryData">
          <mat-radio-group aria-label="Select an option" class="d-flex flex-column" [(ngModel)]="subCategroy"> 
            <mat-radio-button name="data?._id" class="example-radio-button" [value]="data?._id"
              ss="example-radio-button radio-class" (change)="onSubCategoryChange($event,data?._id)">
              {{data?.name}}
            </mat-radio-button>
          </mat-radio-group>
          <!-- <mat-checkbox class="example-margin" color="primary">{{data?.name}}</mat-checkbox> -->
        </li>
      </ul>
    </div>
    <!-- <div class="sidebar-fillter">
      <div class="sidebar-head ml-2">
        <h4 class="ff-sanspro font-22 font-bold">Gender</h4>
        <div class="d-flex gnder">
          <mat-radio-group aria-label="Select an option" (change)="onGenderChange($event)">
            <mat-radio-button  name="male" class="example-radio-button"  [value]="MALE">Male</mat-radio-button>
            <mat-radio-button  name="female" class="example-radio-button"  [value]="FEMALE">Female</mat-radio-button>

          </mat-radio-group>
          <mat-checkbox class="example-margin mr-3" color="primary">Male</mat-checkbox>
            <mat-checkbox class="example-margin" color="primary"> Female</mat-checkbox> 
        </div>
      </div>
    </div> -->
    <div class="slider-fillter">
      <h4 class="ff-sanspro font-22 font-bold ml-2">Price</h4>
      <div class="range-slider">
        <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (userChange)="sliderEvent()">
        </ngx-slider>
      </div>
    </div>
    <div class="sidebar-fillter">
      <div class="sidebar-head ml-2">
        <h4 class="ff-sanspro font-22 font-bold">Ratings</h4>
        <ul class="fillter-list">
          <li class="fillter-listing">
            <!-- <mat-checkbox class="example-margin" color="primary"> One Star</mat-checkbox> -->
            <mat-radio-group aria-label="Select an option" class="d-flex flex-column" (change)="onRatingChange($event)" [(ngModel)]="star">
              <mat-radio-button class="example-radio-button radio-class" value="1">One Star</mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="2">Two Star</mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="3">Three Star</mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="4">Four Star</mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="5">Five Star</mat-radio-button>
            </mat-radio-group>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar-fillter">
      <div class="sidebar-head ml-2">
        <h4 class="ff-sanspro font-22 font-bold">Sort By</h4>
        <ul class="fillter-list">
          <li class="fillter-listing">
            <mat-radio-group aria-label="Select an option" class="d-flex flex-column" (change)="onSortChange($event)" [(ngModel)]="type">
              <mat-radio-button class="example-radio-button radio-class" value="POPULAR">Popularity</mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="NEWEST">Newest</mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="HIGHTOLOW">Price high to low
              </mat-radio-button>
              <mat-radio-button class="example-radio-button radio-class" value="LOWTOHIGH">Price low to high
              </mat-radio-button>
            </mat-radio-group>
          </li>
        </ul>
        <div class="text-center">
          <button class="btn  btn-primary px-3 text-white  rounded-pill w-50" (click)="resetFilter()">Reset</button>
        </div>
      </div>
    </div>
  </div>
</div>