<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i
            class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box">
                    <img src="assets/images/login.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
                    <div class="auth-content">
                        <h3 class="text-center font-30 ff-playfair">
                            Welcome back!
                            <span class="d-block">You’ve been missed</span>
                        </h3>
                        <div class="top-scroll">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                            <ngx-intl-tel-input class="custom-phone" [inputId]="'adsasd'" [cssClass]="'custom b-0'"
                              [preferredCountries]="preferredCountries"  [enablePlaceholder]="true" [customPlaceholder]="'Phone Number'"  [searchCountryFlag]="true"
                              [searchCountryField]="[
                                      SearchCountryField.Iso2,
                                      SearchCountryField.Name
                                    ]" [selectFirstCountry]="false" [enableAutoCountrySelect]="true"
                              [selectedCountryISO]="CountryISO.Canada" [maxLength]="15" [phoneValidation]="true"
                              [separateDialCode]="separateDialCode" name="phone" formControlName="phone" 
                              appNoSpaceAtFirst>
                            </ngx-intl-tel-input>
                            <input matInput value="" placeholder="" formControlName="phone" class="d-none">
                            <mat-error *ngIf="
                                  submitted &&
                                  phoneForm.controls['phone'].hasError('required')
                                " class="text-left">Phone Number is required.</mat-error>
                            <mat-error *ngIf="
                                submitted &&
                                phoneForm.controls['phone'].hasError('validatePhoneNumber')
                              " class="text-left">Please enter valid phone number.</mat-error>
                            </mat-form-field>
                          </div>
                        <mat-error *ngIf="submitted && phoneForm.controls['phone'].hasError('required')" class="text-left">Phone Number is required.</mat-error>
                        <mat-error *ngIf="submitted && phoneForm.controls['phone'].hasError('validatePhoneNumber') " class="text-left">Please enter valid phone number.</mat-error>
                        <div class="">
                            <input type="password" placeholder="Password" class="custom-control" formControlName="password" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            phoneForm.controls['password'].hasError('required')
                          " class="text-left">Password is required.</mat-error>
                        </div>
                        <div class="d-flex justify-content-between pb-2 pt-3 align-items-center remd">
                            <div class="reminder">
                                <div class="switch d-flex">
                                    <input type="checkbox" id="toggleAll" [(ngModel)]="remember"
                                    [ngModelOptions]="{standalone: true}" />
                                    <label for="toggleAll" class="mr-1"></label>Remember me
                                </div>
                            </div>
                            <h5 class="text-end  "><a href="javascript:void()" class="text-primary"
                                    (click)="forgetPasswordModal()">Forgot Password?</a></h5>
                        </div>
                        <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10">Sign in</button>
                        <h5 class="text-center m-0 pt-4 "><a href="javascript:void()" class="text-secondary" (click)="openLoginEmail()">Continue with
                                Email</a></h5>
                        <h5 class="text-secondary text-center m-0 pt-3">or connect with</h5>
                        <div class="row justify-content-center pt-3">
                            <div class="col-3 text-center" (click)="signInWithFB()">
                                <a href="javascript:void()">
                                    <figure class="social-icon">
                                        <img src="assets/images/facebook.png" alt="facebook">
                                    </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="signInWithGoogle()">
                                <a href="javascript:void()">
                                    <figure class="social-icon ">
                                        <img src="assets/images/google.png" alt="google" >
                                    </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="openAppleAuthWindow()">
                                <a href="javascript:void()">
                                    <figure class="social-icon ">
                                        <img src="assets/images/apple.png" alt="apple">
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <h5 class="text-center text-secondary ">Don’t have an account?<a href="javascript:void()"
                                class="text-primary m-1" (click)="openSignUpPhone()">Sign Up</a></h5>
                    </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>