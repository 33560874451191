import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  value: number = 0;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 200
  };

  @Input() public transferAmount: any;
  @Output() inChange: EventEmitter<any> = new EventEmitter();
  categoryData: any;
  subCategoryData: any;
  categoryId: any;
  categoryIds: any;
  subCategoryIds: any;
  rating: any;
  sortBy: any;
  from: any;
  to: any;
  subscription: any;
  searchCategory: string
  searchSubCategory: string;
  categroy: any
  subCategroy: any
  star: any
  type: any
  constructor(private router: Router,
    private https: HttpsService,
    private fb: FormBuilder, private common: CommonService) {

  }

  ngOnInit(): void {
    if (this.transferAmount) {
      this.options.ceil = Math.floor(this.transferAmount)
      this.highValue = Math.floor(this.transferAmount);

    }
    this.category();
  }

  category() {
    if (this.searchCategory) {
      this.https.httpGetWithHeaderParams('category', 'search=' + this.searchCategory).subscribe((res: any) => {
        if (res['status'] == 200) {
          this.categoryData = res['body']['data']['categories']
        }
      })
    } else {
      this.https.httpGetWithHeader('category').subscribe((res: any) => {
        if (res['status'] == 200) {
          this.categoryData = res['body']['data']['categories']
        }
      })
    }

  }

  onCategoryChange(event, id, data) {
    this.categoryIds = id

    let curr = {
      categoryId: this.categoryIds,
      sortby: this.sortBy,
      rating: this.rating,
    }
    this.inChange.emit(curr);
    this.subCategory(this.categoryIds);
  }

  onSubCategoryChange(event, id) {
    this.subCategoryIds = id
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: this.sortBy,
      rating: this.rating,
    }
    this.inChange.emit(curr);
  }



  onRatingChange(event) {
    this.rating = event.value
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: this.sortBy,
      rating: Number(event.value),
    }
    this.inChange.emit(curr);
  }

  onSortChange(event) {

    this.sortBy = event.value
    
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: this.sortBy,
      rating: Number(event.value) ? Number(event.value): undefined,
    }
    this.inChange.emit(curr);
  }
  sliderEvent() {
    let curr = {
      categoryId: this.categoryIds,
      subCategoryId: this.subCategoryIds,
      sortby: this.sortBy,
      rating: this.rating,
      from: this.value,
      to: this.highValue
    }
    this.inChange.emit(curr);
  }
  subCategory(id) {
    if (this.searchSubCategory) {
      this.https.httpGetWithHeaderParams('subcategory', 'id=' + this.categoryIds + '&search=' + this.searchSubCategory).subscribe((res: any) => {
        (res['status'] == 200)
        {
          this.categoryData = res['body']['data']['categories']
        }
      })
    } else {
      this.https.httpGetWithHeaderParams('subcategory', 'id=' + this.categoryIds).subscribe((res: any) => {
        if (res['status'] == 200) {
          this.subCategoryData = res['body']['data']['subcategories']

        }
      })

    }
  }

  resetFilter() {
    this.value = 0
    this.categroy = null
    this.subCategroy = null
    this.star = null
    this.type = null
    this.highValue = this.transferAmount
    let curr = {}
    this.inChange.emit(curr);

  }

}
