import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, retry, tap } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private commonService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
  ) {
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      this.token = localStorage.accessToken;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.start();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          this.router.navigateByUrl('/pages/home');
        }
        if (error.status == 401) {
          this.router.navigateByUrl('/pages/home');
          localStorage.clear();
          this.commonService.setProfile();
          // 401 handled in auth.interceptor
          // this.commonService.presentsToast('error','top-end','you are logout for security purpose.');
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.stop();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (eventRes.body.statusCode === 400) {
              if (eventRes.body.error) {
                this.commonService.presentsToast(
                  'error',
                  'top-end',
                  eventRes.body.error
                );
                // this.commonService.presentsToast('error','top-end',eventRes.body.error);
              } else if (eventRes.body.message) {
                this.commonService.presentsToast(
                  'error',
                  'top-end',
                  eventRes.body.message
                );
                // this.commonService.presentsToast('error','top-end',eventRes.body.message);
              }
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.commonService.presentsToast(
                'warning',
                'top-end',
                'Please Login to Continue'
              );
              localStorage.clear();
              this.router.navigateByUrl('/pages/home');
              break;
            case 500:
              /*** If api does not respond  ** */
              this.commonService.presentsToast(
                'warning',
                'top-end',
                error.error.message

              );
              break;
            case 404:
              /*** If api does not respond  ** */
              this.commonService.presentsToast(
                'warning',
                'top-end',
                error.error.message
              );
              break;

            default:
              if (error.error) {
                error.error.message &&
                  this.commonService.presentsToast(
                    'error',
                    'top-end',
                    error.error.message
                  );
                // this.commonService.presentsToast('error','top-end',error.error.message);
              } else {
                error.message &&
                  this.commonService.presentsToast(
                    'error',
                    'top-end',
                    error.message
                  );
                // this.commonService.presentsToast('error','top-end',error.message);
              }
          }
        }
      )
    );
  }
}
