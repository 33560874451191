import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpsService } from '../http/http.service';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  pageTitle: any = '';
  adminProfile: any = '';
  exportEvent: Subject<boolean> = new Subject();
  userDetails = new BehaviorSubject<any>('');
  currentLang = new BehaviorSubject<any>('');
  dashboardData = new BehaviorSubject<any>('')
  locateData = new BehaviorSubject<any>({});
  public userRefresh = new BehaviorSubject<any>({})
  cardData: any;

  constructor() {
    if (localStorage.getItem('akunamatata_web_user')) {
      this.setProfile();
    }
    if (localStorage.getItem('akunamatata_web_address_location')) {
      this.setLocation();
    }
   }

  
  setProfile() {
    if (localStorage.getItem('akunamatata_web_user')) {
      var value = JSON.parse(localStorage.getItem('akunamatata_web_user'));
      this.userDetails.next(value);
    } else {
      this.userDetails.next('');
    }
  }

  getProfile(): Observable<boolean> {
    return this.userDetails.asObservable()
  }

setDashboard(data){
  this.dashboardData.next(data);
}
  getDashboard(): Observable<boolean> {
    return this.dashboardData
  }
  setLocation() {
    if (localStorage.getItem('akunamatata_web_address_location')) {
      var value = JSON.parse(localStorage.getItem('akunamatata_web_address_location'));
      this.locateData.next(value);
    }
  }

 

  getLocation(): Observable<boolean> {
    return this.locateData.asObservable()
  }
  presentsToast(type: any, position: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000
    });
    Toast.fire({
      title: message,
      icon: type
    });
  }

  confirmToast() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  openDialog(type, message) {
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: type,
      width: '300',
      imageHeight: '10',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Close'
    })
  }

 

  validateVideoFile(fileData) {
    if (fileData.type == 'image/jpeg' || fileData.type == 'image/png' || 
        fileData.type == 'image/jpg' || fileData.type == 'image/svg' || 
        fileData.type == 'image/webp' || fileData.type == 'image/gif' ||
        fileData.type == 'image/pdf' || fileData.type == 'application/pdf') {
        
      return false;
    } else {
      return true;
    }
  }

}
