 <div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box"> 
                    <img src="assets/images/forgot-img.jpg" alt="" class="img-fluid">           
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="forgetForm" (ngSubmit)="onSubmit()">
                    <div class="auth-content">
                        <div class="d-flex align-content-center  justify-content-center">
                            <figure>
                                <img src="assets/images/forgot-logo.svg" alt="">
                            </figure>
                        </div>
                        <h3 class="text-center font-30 ff-playfair">
                            Forget Password
                        </h3>
                        <p class="text-secondary text-center font-16 ">Enter your email and we’ll send you instructions on how to reset your password.</p>
                        <div class="form-group">                           
                            <input type="text" *ngIf="showEmail" placeholder="Email" class="custom-control" formControlName="email" appNoSpaceAtFirst>       
                            <ngx-intl-tel-input class="form-control" [inputId]="'adsasd'" [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries" [enablePlaceholder]="true"
                                [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]" [selectFirstCountry]="false" [enableAutoCountrySelect]="true"
                                [selectedCountryISO]="CountryISO.Canada" [maxLength]="15" [phoneValidation]="true"
                                [separateDialCode]="separateDialCode" name="phone" *ngIf="showPhone" formControlName="phone" appNoSpaceAtFirst
                                >
                            </ngx-intl-tel-input>      
                        <mat-error *ngIf=" showPhone &&
                        submitted &&
                        forgetForm.controls['phone'].hasError('required')
                      " class="text-left">Phone is required.</mat-error> 
                      <mat-error *ngIf=" showEmail &&
                      submitted &&
                      forgetForm.controls['email'].hasError('required')
                    " class="text-left">Email  is required.</mat-error>          
                        </div>
                        <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10">Continue</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


