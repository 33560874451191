<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box"> 
                    <img src="assets/images/signup.jpg" alt="" class="img-fluid">           
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="signUpPhoneForm" (ngSubmit)="btn_signUp()">
                    <div class="auth-content ">
                        <h3 class="text-center font-30 ff-playfair">
                            Sign Up
                        </h3>
                        <div class="form-group">
                            <input type="text" placeholder="First Name" class="custom-control" formControlName="firstName" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            signUpPhoneForm.controls['firstName'].hasError('required')
                          " class="text-left">First Name is required.</mat-error>  
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Last Name" class="custom-control" formControlName="lastName" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            signUpPhoneForm.controls['lastName'].hasError('required')
                          " class="text-left">Last Name is required.</mat-error>  
                        </div>
                        <div class="form-group">
                            <mat-form-field class="w-100">
                            <ngx-intl-tel-input class="custom-phone" [inputId]="'adsasd'" [cssClass]="'custom b-0'"
                              [preferredCountries]="preferredCountries"  [enablePlaceholder]="true" [customPlaceholder]="'Phone Number'"  [searchCountryFlag]="true"
                              [searchCountryField]="[
                                      SearchCountryField.Iso2,
                                      SearchCountryField.Name
                                    ]" [selectFirstCountry]="false" [enableAutoCountrySelect]="true"
                              [selectedCountryISO]="CountryISO.Canada" [maxLength]="15" [phoneValidation]="true"
                              [separateDialCode]="separateDialCode" name="phone" formControlName="phone" 
                              appNoSpaceAtFirst>
                            </ngx-intl-tel-input>
                            <input matInput value="" placeholder="" formControlName="phone_number" class="d-none">
                            <mat-error *ngIf="
                                  submitted &&
                                  signUpPhoneForm.controls['phone'].hasError('required')
                                " class="text-left">Phone Number is required.</mat-error>
                            <mat-error *ngIf="
                                submitted &&
                                signUpPhoneForm.controls['phone'].hasError('validatePhoneNumber')
                              " class="text-left">Please enter valid phone number.</mat-error>
                            </mat-form-field>
                          </div>
                        <div class="form-group">                           
                            <input type="password" placeholder="Password" class="custom-control" formControlName="password" appNoSpaceAtFirst>  
                            <mat-error *ngIf="
                            submitted &&
                            signUpPhoneForm.controls['password'].hasError('required')
                          " class="text-left">Phone Number is required.</mat-error>                
                        </div>   
                        <div class="form-group">                           
                            <input type="password" placeholder="Confirm Password" class="custom-control" formControlName="confirmPassword" appNoSpaceAtFirst> 
                            <mat-error *ngIf="
                            submitted &&
                            signUpPhoneForm.controls['confirmPassword'].hasError('required')
                          " class="text-left">Phone Number is required.</mat-error>   
                          <mat-error *ngIf="
                          submitted &&
                          signUpPhoneForm.controls['confirmPassword'].hasError('mustMatch')
                        " class="text-left">Password must match.</mat-error>               
                        </div>          
                       
                        <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10">Sign Up</button>
                        <h5 class="text-center m-0 pt-4 "><a href="javascript:void()" class="text-secondary" (click)="openSignUpEmail()">Continue with Email</a></h5>
                        <h5 class="text-secondary text-center m-0 pt-3">or connect with</h5>
                        <div class="row justify-content-center pt-3">
                            <div class="col-3 text-center" (click)="signInWithFB()">
                                <a href="javascript:void()">
                                <figure class="social-icon">
                                    <img src="assets/images/facebook.png" alt="facebook">
                                </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="signInWithGoogle()">
                                <a href="javascript:void()">
                                <figure class="social-icon ">
                                    <img src="assets/images/google.png" alt="google" >
                                </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="openAppleAuthWindow()">
                                <a href="javascript:void()">
                                <figure class="social-icon ">
                                    <img src="assets/images/apple.png" alt="apple">
                                </figure>
                                </a>
                            </div>
                        </div>
                        <h5 class="text-center text-secondary ">Already have an account<a href="javascript:void(0)" class="text-primary m-1" (click)="openLoginPhone()" >Sign in</a></h5>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
