import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { LoginComponent } from '../login/login.component';
import { PhoneOtpComponent } from '../phone-otp/phone-otp.component';
import { PhoneSingupComponent } from '../phone-singup/phone-singup.component';
import { SetupProfileComponent } from '../setup-profile/setup-profile.component';

@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.scss']
})
export class PhoneLoginComponent implements OnInit {


  submitted: boolean=false;
  phoneForm: FormGroup;
  isLoggedIn: boolean;
  userDetails: any;
  user: any;
  remember: boolean = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Kuwait];
  yourRedirectURIVar: any = 'https://dev.webdevelopmentsolution.net/akunamatata/web/pages/home';
  clientId: any = 'dev.webdevelopmentsolution';
  jwtHelper = new JwtHelperService();
  
  constructor(public modalRef: BsModalRef,  private modalService: BsModalService,private router: Router,private socialAuthService: SocialAuthService,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService) {
      if (sessionStorage.getItem("rememberLoginPhone")) {
        let data = JSON.parse(sessionStorage.getItem("rememberLoginPhone"));
        this.remember = true;
        this.phoneForm = this.fb.group({
          phone: [ {
            number: data.countryCode
              ? data.countryCode + ' ' + data.phone
              : ''
          }, Validators.required
          ],
          countryCode:[data.countryCode],
          password: [data.password,Validators.required]
        });
      } else {
        this.phoneForm = this.fb.group({
          phone: ["", Validators.required],
          countryCode:[""],
          password: ["",Validators.required]
        });
      }
     }

  ngOnInit(): void {
  }

  forgetPasswordModal() {
    this.modalRef.hide();
    localStorage.clear();
    localStorage.setItem('phone','d')
    this.modalService.show(ForgetPasswordComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openPhoneOtp() {
    this.modalRef.hide();
    this.modalService.show(PhoneOtpComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  setupProfile(){
    this.modalRef.hide();
    this.modalService.show(SetupProfileComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openLoginEmail(){
    this.modalRef.hide();
    this.modalService.show(LoginComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openSignUpPhone(){
    this.modalRef.hide();
    this.modalService.show(PhoneSingupComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  onSubmit() {
    this.submitted = true;
    if (this.phoneForm.valid) {
      var codeLength = this.phoneForm.value.phone.dialCode.length;
      this.phoneForm.patchValue({
        phone:this.phoneForm.value.phone.e164Number.slice(codeLength),
        countryCode:this.phoneForm.value.phone.dialCode,
      })
      if (this.remember) {
        sessionStorage.setItem(
          "rememberLoginPhone",
          JSON.stringify(this.phoneForm.value)
        );
      } else {
        sessionStorage.clear();
      }
     
      let data = this.phoneForm.value;
      data['deviceType'] = "WEB";
       this.https.httpPost('login', data).subscribe((res: any) => {
        this.user = res['data']['user'];
        if (res['status']==200) {
          if (this.user.isPhoneVerified == true) {
            this.isLoggedIn = true;
            this.setLoginData(res);
          }
          else {
            res['data']['isPhoneLogin']=true
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            
            if (localStorage.getItem('akunamatata_web_user')) {
              this.userDetails = JSON.parse(localStorage.getItem('akunamatata_web_user'));
               this.openPhoneOtp();
            }
          }
        } 
      })
    } else {
      this.phoneForm.markAllAsTouched();
    }
  }


  setLoginData(response) {
    if (this.isLoggedIn) {
      localStorage.setItem('akunamatata_web_user', JSON.stringify(response['data']));
      localStorage.setItem('accessToken',response['data']['token']);
      if (response['data']['user'] && response['data']['user']['isPhoneVerified'] === false) {
        this.openPhoneOtp();
      } else if (response['data']['user'] && response['data']['user']['isProfileCompleted'] === false) {
        this.setupProfile();
      }else
      {
        this.modalRef.hide();
        this.common.setProfile();
        this.common.presentsToast('success', 'top-end','User Login Successfully, Welcome to Akunamatata')
      }
    }
  }


  signInWithFB(): void {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "FACEBOOK",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Akunamatata");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();
          } else {
            this.common.presentsToast('error', 'top-end',res['message'])

          }
        })
      }
    })
  }


  signInWithGoogle() {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "GOOGLE",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Akunamatata");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();

            } else {
            this.common.presentsToast('error', 'top-end',res['message'])
          }
        })
      }
    });
  }

  openAppleAuthWindow() {
    window.open(
      'https://appleid.apple.com/auth/authorize?' +
        `client_id=${this.clientId}&` +
        `redirect_uri=${encodeURIComponent(this.yourRedirectURIVar)}&` +
        //`redirect_uri=${this.yourRedirectURIVar}&` +
        'response_type=code id_token&' +
        'state=state&' +
        'nonce=nonce&' +
        //'scope=name email&' +
        'response_mode=fragment',
      '_self'
    );
  }
}
