import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { MustMatch } from 'src/app/services/must-match.validators';
import { PhoneLoginComponent } from '../phone-login/phone-login.component';
import { PhoneOtpComponent } from '../phone-otp/phone-otp.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-phone-singup',
  templateUrl: './phone-singup.component.html',
  styleUrls: ['./phone-singup.component.scss']
})
export class PhoneSingupComponent implements OnInit {

  submitted: boolean;
  signUpPhoneForm: FormGroup;
  isLoggedIn: boolean;
  userDetails: any;
  user: any;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Kuwait];
  yourRedirectURIVar: any = 'https://dev.webdevelopmentsolution.net/akunamatata/web/pages/home';
  clientId: any = 'dev.webdevelopmentsolution';
  jwtHelper = new JwtHelperService();

  constructor(public modalRef: BsModalRef, private modalService: BsModalService,private router: Router,private socialAuthService: SocialAuthService,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService) { }

  ngOnInit(): void {
    this.signUpPhoneForm = this.fb.group({
      firstName:['',Validators.required],
      lastName:['',Validators.required],
      phone: ['', Validators.required],
      countryCode: [''],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: MustMatch('password', 'confirmPassword'),
    });
  }
  openPhoneOtp() {
    this.modalRef.hide();
    this.modalService.show(PhoneOtpComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openSignUpEmail(){
    this.modalRef.hide();
    this.modalService.show(SignupComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  openLoginPhone(){
    this.modalRef.hide();
    this.modalService.show(PhoneLoginComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }

  btn_signUp(){
    this.submitted = true;
    if(this.signUpPhoneForm.valid){
      var codeLength = this.signUpPhoneForm.value.phone.dialCode.length;
      this.signUpPhoneForm.patchValue({
        phone:this.signUpPhoneForm.value.phone.e164Number.slice(codeLength),
        countryCode:this.signUpPhoneForm.value.phone.dialCode,
      })
      let data = this.signUpPhoneForm.value;
        data['deviceType']='WEB';
        delete data['confirmPassword'];
        this.https.httpPost('signUp', data).subscribe((res: any) => {
          if(res['status']==201){
            if(res['data']){
              res['data']['isPhoneSignUp']=true
              localStorage.setItem('akunamatata_web_user',JSON.stringify(res['data']));
              localStorage.setItem('accessToken',res['data']['token']);
              this.openPhoneOtp();
            }
          }
        })
    }else{
      this.signUpPhoneForm.markAllAsTouched();
    }
  }

  signInWithFB(): void {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "FACEBOOK",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Akunamatata");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();
          } else {
            this.common.presentsToast('error', 'top-end',res['message'])

          }
        })
      }
    })
  }


  signInWithGoogle() {
    // this.common.showLoader = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
      // this.common.showLoader = false;
      if (res) {
        let body = {
          "email": res.email,
          "socialId": res.id,
          "deviceType": "WEB",
          "socialType": "GOOGLE",
        }
        if (res['firstName'] && res['firstName'] != null) {
          body['firstName'] = res['firstName'];
        }
        if (res['lastName'] && res['lastName'] != null) {
          body['lastName'] = res['lastName'];
        }
        if (res['photoUrl'] && res['photoUrl'] != null) {
          body['profilePic'] = res['photoUrl'];
        }
        this.https.httpPost('socialLogin', body).subscribe((res: any) => {
          if (res['status'] == 200) {
            this.common.presentsToast('success', 'top-end',"User Login Successfully, Welcome to Akunamatata");
            localStorage.setItem('akunamatata_web_user', JSON.stringify(res['data']));
            localStorage.setItem('accessToken',res['data']['token']);
              this.modalRef.hide();
              this.common.setProfile();

            } else {
            this.common.presentsToast('error', 'top-end',res['message'])
          }
        })
      }
    });
  }

  openAppleAuthWindow() {
    window.open(
      'https://appleid.apple.com/auth/authorize?' +
        `client_id=${this.clientId}&` +
        `redirect_uri=${encodeURIComponent(this.yourRedirectURIVar)}&` +
        //`redirect_uri=${this.yourRedirectURIVar}&` +
        'response_type=code id_token&' +
        'state=state&' +
        'nonce=nonce&' +
        //'scope=name email&' +
        'response_mode=fragment',
      '_self'
    );
  }
}
