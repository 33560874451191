<div class="auth-block">
  <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i
      class="mdi mdi-window-close text-white"></i></button>
  <div class="modal-body p-0">
    <div class="auth-content">
      <form [formGroup]="profileForm" (ngSubmit)="btn_Continue()">
        <h2 class="font-bold fs-2 ff-playfair text-center mb-4">Setup Profile</h2>
        <div class="row">
          <div class="col-md-12">
            <div class="custom-upload text-center mb-4">
              <button class="custom-upload-btn mx-auto rounded-circle bg-light-secondary">
                <figure>
                  <img style="
                  border-radius: 100%;
                  height: auto;
                  max-width: 100%;
              "*ngIf="imageSrc"  [src]="imageSrc" />
                  <img *ngIf="!imageSrc" style="
                  border-radius: 11%;
                  height: auto;
                  max-width: 66%;
              "src="assets/images/camera.png" />
                </figure>
                <input type="file" style="cursor: pointer" (change)="onFileChange($event)" id="myfile" name="myfile"
                  class="custom-upload-control">
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-element">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput value="" placeholder="First Name" formControlName="firstName" appNoSpaceAtFirst>
                  <mat-error *ngIf="
                  submitted &&
                  profileForm.controls['firstName'].hasError('required')
                " class="text-left">First Name is required.</mat-error> 
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-element">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput value="" placeholder="Last Name" formControlName="lastName" appNoSpaceAtFirst>
                  <mat-error *ngIf="
                  submitted &&
                  profileForm.controls['lastName'].hasError('required')
                " class="text-left">Last Name is required.</mat-error> 
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-element">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input type="email" matInput value="" placeholder="Email" formControlName="email"  [readonly]="isEmail" appNoSpaceAtFirst>
                  <mat-error *ngIf="
                  submitted &&
                  profileForm.controls['email'].hasError('required')
                " class="text-left">Email is required.</mat-error> 
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-element">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <ngx-intl-tel-input class="custom-phone" [inputId]="'adsasd'" [cssClass]="'custom b-0'"
                    [preferredCountries]="preferredCountries"  [enablePlaceholder]="true" [customPlaceholder]="'Phone Number'"  [searchCountryFlag]="true"
                    [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]" [selectFirstCountry]="false" [enableAutoCountrySelect]="true"
                    [selectedCountryISO]="CountryISO.Canada" [maxLength]="15" [phoneValidation]="true"
                    [separateDialCode]="separateDialCode" name="phone" formControlName="phone"
                    appNoSpaceAtFirst>
                  </ngx-intl-tel-input>
                  <mat-error *ngIf="
                    submitted &&
                    profileForm.controls['phone'].hasError('required')
                  " class="text-left">Phone Number is required.</mat-error>
                  <mat-error *ngIf="
                  submitted &&
                  profileForm.controls['phone'].hasError('validatePhoneNumber')
                " class="text-left">Please enter valid phone number.</mat-error>
                  <input matInput value="" placeholder="" formControlName="phone" class="d-none">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-element">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput type="text" ngx-google-places-autocomplete #placesRef="ngx-places"
                    (keyup)="onKeyUpp($event.target.value)" (change)="pickupChange($event.target.value)"
                    (click)="handlePickUpChnage($event)" (onAddressChange)="handlePickUpChnage($event)"
                    placeholder="Location" formControlName="homeAddress" appNoSpaceAtFirst>
                    <mat-error *ngIf="
                    submitted &&
                    profileForm.controls['homeAddress'].hasError('required')
                  " class="text-left">Address is required.</mat-error> 
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-element">
              <div class="form-group">
                <h3 class="font-bold">Gender</h3>
                <mat-radio-group aria-label="Select an option" formControlName="gender">
                  <mat-radio-button value="MALE" class="me-3">Male</mat-radio-button>
                  <mat-radio-button value="FEMALE" class="me-3">Female</mat-radio-button>
                  <mat-radio-button value="BOTH">Both</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="
                submitted &&
                profileForm.controls['gender'].hasError('required')
              " class="text-left">Gender is required.</mat-error> 
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center pb-5">
          <button class="btn btn-primary w-50 text-white p-b-10 p-t-10 font-20 rounded-pill"
            >Continue</button>
        </div>

      </form>
    </div>
  </div>
</div>