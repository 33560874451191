import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { apiList } from './api-list';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class HttpsService {
  baseUrl: string = environment.baseUrl;
  socket: any;

  constructor(
    private http: HttpClient,
    private apiList: apiList,
    private common: CommonService
  ) { }

  httpPost(url: string, params): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPutWithHeader(url: string, id, params: any): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      this.header(false)
    );
  }


  httpPutWithHeaderBody(
    url: string,
    params: any,
    isFormData
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      params,
      this.header(isFormData)
    );
  }

  httpPutWithHeaderParamAndId(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
    return this.http.patch(
      this.baseUrl + this.apiList[url] + '/' + id + '?' + params,
      {},
      this.header(isFormData)
    );
  }

  httpPutWithHeaderBodyAndId(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      this.header(isFormData)
    );
  }

  httpPutWithHeaderBodyAndIds(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + '?' + id,
      params,
      this.header(isFormData)
    );
  }

  httpDeleteWithHeaderAndId(url: string, id, isFormData): Observable<Object> {
    return this.http.delete(
      this.baseUrl + this.apiList[url] + '/' + id,
      this.header(isFormData)
    );
  }

  httpDeleteWithHeader(url: string, param, isFormData): Observable<Object> {
    return this.http.delete(
      this.baseUrl + this.apiList[url] + '?' + param,
      this.header(isFormData)
    );
  }

  httpPostWithHeader(url: string, params: any, isFormData): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.header(isFormData)
    );
  }

  httpPostWithHeaderId(url: string, id, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '/' + id,
      params,
      this.header(false)
    );
  }

  httpPostWithHeaderParam(url: string, query, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + '?' + query,
      params,
      this.header(false)
    );
  }



  httpGetWithHeaderParams(url: string, params): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '?' + params,
      this.header(false)
    );
  }
  httpGetWithHeaderParamAndId(url: string, id, params): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '/' + id + '?' + params,
      this.header(false)
    );
  }

  httpGetWithHeader(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url], this.header(false));
  }

  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }


  httpGetHeaderById(url: string, id: string): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + '/' + id,
      this.header(false)
    );
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append('profilePic', fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: 'multipart/form-data',
      authorization: localStorage.accessToken,
    });
    const headersObj = { headers };
    return this.http.post(
      this.baseUrl + this.apiList['profilePic'],
      formData,
      headersObj
    );
  }

  header(isFormData) {
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      var headers;
      if (isFormData) {
        headers = new HttpHeaders({
          mimeType: 'multipart/form-data',
          authorization: localStorage.accessToken,
        });
      } else {
        headers = new HttpHeaders({
          'cache-control': 'no-cache',
          'content-type': 'application/json',
          // "mimeType": "multipart/form-data",
          authorization: localStorage.accessToken,
        });
      }
      let option = {
        headers,
      };

      option['observe'] = 'response';
      return option;
    } else {
      var headers;
      if (isFormData) {
        headers = new HttpHeaders({
          mimeType: 'multipart/form-data',
          'guest': 'true',
        });
      } else {
        headers = new HttpHeaders({
          'cache-control': 'no-cache',
          'content-type': 'application/json',
          'guest': 'true',

          // "mimeType": "multipart/form-data",
        });
      }
      let option = {
        headers,
      };

      option['observe'] = 'response';
      return option;
    }
  }
}
