<div class="auth-block">
    <button #closeModal type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i
            class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box">
                    <img src="assets/images/login.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="auth-content">
                        <h3 class="text-center font-30 ff-playfair">
                            Welcome back!
                            <span class="d-block">You’ve been missed</span>
                        </h3>
                        <div class="form-group">
                            <input type="text" placeholder="Email Id" class="custom-control" formControlName="email"
                                appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            loginForm.controls['email'].hasError('required')
                          " class="text-left">Email is required.</mat-error>
                            <mat-error *ngIf="
                          submitted &&
                          loginForm.controls['email'].hasError('pattern')
                        " class="text-left">Email is not valid.</mat-error>
                        </div>
                        <div class="">
                            <input type="password" placeholder="Password" class="custom-control"
                                formControlName="password" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            loginForm.controls['password'].hasError('required')
                          " class="text-left">Password is required.</mat-error>
                        </div>
                        <div class="d-flex justify-content-between pb-2 pt-3 align-items-center remd">
                            <div class="reminder">
                                <div class="switch d-flex">
                                    <input type="checkbox" id="toggleAll" [(ngModel)]="remember"
                                        [ngModelOptions]="{standalone: true}" />
                                    <label for="toggleAll" class="mr-1"></label>Remember me
                                </div>
                            </div>
                            <h5 class="text-end  "><a href="javascript:void()" class="text-primary"
                                    (click)="forgetPasswordModal()">Forgot Password?</a></h5>
                        </div>
                        <button class="btn btn-primary px-3 text-white w-100  p-t-10 p-b-10 rounded-pill">Sign
                            in</button>
                        <h5 class="text-center m-0 pt-4 "><a href="javascript:void()" class="text-secondary"
                                (click)="phoneModal()">Continue with Phone</a></h5>
                        <h5 class="text-secondary text-center m-0 pt-3">or connect with</h5>
                        <div class="row justify-content-center pt-3">
                            <div class="col-3 text-center" (click)="signInWithFB()">
                                <a href="javascript:void()">
                                    <figure class="social-icon">
                                        <img src="assets/images/facebook.png" alt="facebook">
                                    </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="signInWithGoogle()">
                                <a href="javascript:void()">
                                    <figure class="social-icon ">
                                        <img src="assets/images/google.png" alt="google">
                                    </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="openAppleAuthWindow()">
                                <a href="javascript:void()">
                                    <figure class="social-icon ">
                                        <img src="assets/images/apple.png" alt="apple">
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <h5 class="text-center text-secondary ">Don’t have an account?<a href="javascript:void(0)"
                                class="text-primary m-1" (click)="signUpModal()">Sign Up</a></h5>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>